<template>
  <div v-if="dataReady" class="onboarding-review-admin mt-6 md:mt-8">
    <vs-row id="profile-page" class="mt-5" v-if="isReady">
      <!-- Business Information -->
      <h3 class="font-medium text-xl md:text-3xl text-dark-blue mb-4 md:mb-6">Onboarding application</h3>
      <vx-card class="accordin-card">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="businessInfoCollapse" :open="isReady">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="edit" alt="edit">
                  </div>
                  <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">Business information</h3>
                  <div class="ml-auto"></div>
                </div>
              </div>

              <vs-row>
                <!-- General Information -->
                <div class="w-full mb-8">
                  <div class="flex mb-2 items-center ml-4">
                    <div class="flex justify-center items-center" style="max-width:20px;">
                      <img class="icon-control" :src="editlight" alt="edit">
                    </div>
                    <h4 class="text-xl mb-0 font-normal ml-5">General information</h4>
                  </div>
                  <hr class="line-hr mb-8 md:mb-10 w-full ml-4">

                  <GeneralInformation :generalInfo="onboardDetail.businessInfo" />
                </div>

                <div class="w-full mb-8">
                  <div class="flex mb-2 items-center ml-4">
                    <div class="flex justify-center items-center" style="max-width:20px;">
                      <img class="icon-control" :src="editlight" alt="edit">
                    </div>
                    <h4 class="text-xl mb-0 font-normal ml-5">Business profile</h4>
                  </div>
                  <hr class="line-hr w-full ml-4">
                  <vs-row>
                    <div class="w-full">
                      <BusinessProfile :onboardingCustomFields="onboardDetail.onboardingCustomFields" />
                    </div>
                  </vs-row>
                </div>

                <!-- contact details -->
                <div class="w-full mb-8">
                  <div class="flex mb-2 items-center ml-4">
                    <div class="flex justify-center items-center" style="max-width:20px;">
                      <img class="icon-control" :src="editlight" alt="edit">
                    </div>
                    <h4 class="text-xl mb-0 font-normal ml-5">Contact details</h4>
                  </div>
                  <hr class="line-hr mb-8 w-full ml-4" />
                  <ContactDetails :contactDetails="onboardDetail.contactDetails" />
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Business ownership -->
      <vx-card class="accordin-card mt-6" id="businessOwnershipArea">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="businessOwnership" :open="isReady">
              <div slot="header">
                <div class="flex flex-row items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="bank" alt="bank">
                  </div>
                  <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">Business ownership</h3>
                </div>
              </div>
              <vs-row>
                <div class="w-full">
                  <BusinessEntity
                    ref="businessOwnershipChild"
                    :onboardingRequestId="onboardingId"
                    :entityList="onboardDetail.businessOwnership"
                    :businessOwnershipCustomFields="businessOwnershipCustomFields"
                  />
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Bank Account -->
      <vx-card class="accordin-card mt-4">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="bankCollapse" :open="isReady">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="bank" alt="bank">
                  </div>
                  <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">Bank accounts</h3>
                </div>
              </div>
              <vs-row>
                <div class="w-full">
                  <BankAccounts :bankAccount="onboardDetail.bankAccount" :entityList="onboardDetail.businessOwnership" />
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Product Selection -->
      <vx-card class="accordin-card mt-4">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="templateCollapse" :open="isReady">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="product" alt="product">
                  </div>
                  <h3 class="text-left card-title  font-normal lg:text-2xl md:text-xl text-lg">Product selection</h3>
                </div>
              </div>

              <div>
                <ProductSelection :product="onboardDetail.productSelection" />
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Agreement -->
      <vx-card class="accordin-card mt-4" v-if="legalsReady">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item ref="onboardingCollapse" :open="isReady">
              <div slot="header">
                <div class="flex items-center">
                  <div class="icon-wrapper">
                    <img class="icon-control" :src="edit" alt="home" />
                  </div>
                  <h3 class="text-left card-title font-normal lg:text-2xl md:text-xl text-lg">{{ currentLegal.documentName }}</h3>
                  <div class="ml-auto"></div>
                </div>
              </div>

              <div class="w-full">
                <OnboardingAgreement :legalsInfo="currentLegal" :terms="onboardDetail.terms" />
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>
    </vs-row>
    <div class="mt-10" v-if="dataError">
      <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
        <img src="@/assets/images/pages/404.png" alt="graphic-404" class="mx-auto mb-4 max-w-full"/>
        <h1 class="sm:mx-0 mx-4 mb-12 text-5xl d-theme-heading-color">404 - Data Not Found!</h1>

        <vs-button size="large" @click="goToDashboard">Back to Home</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import GeneralInformation from "./businessInformation/GeneralInformation.vue";
import ContactDetails from "./businessInformation/ContactDetails.vue";
import BusinessProfile from "./businessProfile/BusinessProfile.vue";
import BankAccounts from "./bankAccount/bankAccounts.vue";
import ProductSelection from "./product/ProductSelection.vue";
const editlight = require("@/assets/images/edit-grey.svg");
const bank = require("@/assets/images/bank.svg");
const product = require("@/assets/images/product.svg");
const edit = require("@/assets/images/edit-blue.svg");
import BusinessEntity from "./businessEntity/BusinessEntity";
import OnboardingAgreement from "./OnboardingAgreement/OnboardingAgreement";
import {PlusIcon, Edit2Icon} from "vue-feather-icons";
import S3FileGenerator from "@/views/components/S3FileGenerator"

export default {
  name: "index",
  components: {
    GeneralInformation,
    ContactDetails,
    BusinessProfile,
    BankAccounts,
    ProductSelection,
    S3FileGenerator,
    BusinessEntity,
    OnboardingAgreement,
    PlusIcon,
    Edit2Icon
  },
  data() {
    return {
      isReady: false,
      dataReady: false,
      dataError: false,
      filePath: process.env.VUE_APP_API_URL + "uploads/exports/",
      onboardingId: "",
      onboardDetail: {},
      editlight: editlight,
      bank: bank,
      product: product,
      edit: edit,
      businessOwnershipCustomFields: [],
      legalId: "",
      currentLegal: {},
      legalsReady: false,
    };
  },

  mounted() {
    this.onboardingId = this.$route.params.onboardingId;
    if(this.$refs.businessInfoCollapse)
      this.$refs.businessInfoCollapse.maxHeight = "none !important";
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    }
  },

  methods: {
    ...mapActions("partnerDashboard", ["fetchOnboardDetailWithMerchantId"]),
    ...mapActions("legals", ["fetchAllLegals"]),
    
    async getDetail() {
      this.$vs.loading();
      let data = {
        onboardingId: this.$route.params.onboardingId,
        merchantId: this.partnerId
      }
      await this.fetchOnboardDetailWithMerchantId(data)
          .then((result) => {
            if (result) {
              this.onboardDetail = result.data.data;
              this.businessOwnershipCustomFields = result.data.data.onboardingRequest.businessOwnershipFields;
               if (result.data.data.terms.hasOwnProperty("generalTermsId")) {
                this.legalId = result.data.data.terms.generalTermsId;
                this.getTerms();
              }
              this.isReady = true;
            } else {
              this.dataError = true;
            }
            this.dataReady = true;
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.dataReady = true;
            this.dataError = true;
            this.$vs.notify({
              title: "Error",
              text: "Error fetching details",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
    },
    goToDashboard() {
      this.$router.push({ name: "partner-dashboard" });
    },
    getTerms() {
      this.fetchAllLegals("DASHBOARD")
        .then((result) => {
          this.$vs.loading.close();

          const legals = result.data.data.legals;
          const latestPrivacy = result.data.data.privacy;
          const latestWebsiteTerms = result.data.data.websiteTerms;
          const legalPageUrl= `${process.env.VUE_APP_ROOT_API}pages/terms-and-conditions/`;
          this.currentLegal = legals.find((el) => el._id === this.legalId);

          if (this.currentLegal.explicitTerms) {
            this.currentLegal.explicitTerms = this.replaceLegalContent(
              this.currentLegal.explicitTerms,
              {
                fullTerms: `<a href="${legalPageUrl}${this.currentLegal._id}?type=DASHBOARD" target="_blank">${this.currentLegal.documentName}</a>`,
                privacy: `<a href="${legalPageUrl}${latestPrivacy._id}?type=DASHBOARD&fullDocument=true" target="_blank">${latestPrivacy.documentName}</a>`,
                generalWebsiteTerms: `<a href="${legalPageUrl}${latestWebsiteTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${latestWebsiteTerms.documentName}</a>`,
              }
            );
            this.legalsReady = true;
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: "Error fetching legals",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
  },

  beforeMount() {
    this.getDetail();
  }
};
</script>