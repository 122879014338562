<template>
  <div>
    <div class="onboarding-form">
      <div class="flex mt-6 flex-wrap">
        <div class="input-grp">
          <label class="vs-input--label w-full review-label">Trading name</label>
          <p class="textBlack mb-0">{{ payload.tradingName }}</p>
        </div>
      </div>

      <div class="flex mt-6 flex-wrap">
        <div class="input-grp">
          <label class="vs-input--label w-full review-label">Industry</label>
          <p class="textBlack mb-0">{{ payload.industry }}</p>
        </div>

        <div class="input-grp">
          <label class="vs-input--label w-full review-label">Category</label>
          <p class="textBlack mb-0">{{ payload.category }}</p>
        </div>
      </div>

      <div class="flex mt-6 flex-wrap" v-if="payload.websiteUrl">
        <div class="input-grp" v-if="payload.websiteUrl">
          <label class="vs-input--label w-full review-label">Website URL</label>
          <p class="textBlack mb-0">{{ payload.websiteUrl }}</p>
        </div>
      </div>

      <div class="flex mt-6 flex-wrap">
        <div class="input-grp">
          <label class="vs-input--label w-full review-label">Business email address</label>
          <p class="textBlack mb-0">{{ payload.businessEmailAddress }}</p>
        </div>

        <div class="input-grp">
          <label class="vs-input--label w-full review-label">Business phone number</label>
          <p class="textBlack mb-0">{{ payload.businessPhoneNumber }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralInformation",
  props:{
    generalInfo:{type:Object}
  },
  data(){
    return{
      payload:{
        industry: "",
        category: "",
        websiteUrl: "",
        tradingName: "",
        businessEmailAddress: "",
        businessPhoneNumber: "",
      }
    }
  },
  mounted(){
    this.payload = this.generalInfo;
  }
  
}
</script>