<template>
  <vs-row class="mt-2 onboarding-form">
    <vs-col vs-xs="12">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-12 gap-y-6 md:gap-y-8">
        <div class="" v-for="(contact, index) in contactDetails" :key="index">
          <label class="vs-input--label pl-0 review-label w-full block text-base">{{contact.name}}</label>
          <p class="textBlack mb-1">{{contact.email}}</p>
          <p class="textBlack">{{contact.phoneNumber}}</p>
        </div>
      </div>
    </vs-col>
  </vs-row>
</template>

<script>
export default {
  name: "ContactDetails",
  props: ["contactDetails"]
};
</script>