<template>
  <div class="onboarding-form section-cms">
    <div class="pt-2 pb-3">
      <div class="pb-3">
        <p class="text-dark-blue mb-2 md:mb-4">Authorised by:</p>
        <p class="font-light">{{ terms.autorizedPerson.name }} - {{terms.autorizedPerson.position}}</p>
      </div>
      <hr class="line-hr my-4 lg:my-8 w-full" />
    </div>
    <div class="flex w-full items-start" v-if="legalsInfo">
      <div v-html="legalsInfo.explicitTerms"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OnboardingAgreement",
  props: {
    legalsInfo: { type: Object },
    terms: { type: Object },
  },
};
</script>