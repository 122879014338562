<template>
  <div>
    <vs-row v-if="product.productOption.includes('pay-now')">
      <vs-col vs-w="12" class="flex items-center ml-4">
        <div class="flex justify-center items-center" style="max-width: 20px">
          <img class="icon-control" :src="productLight" alt="product">
        </div>
        <h5 class="ml-5 mb-0">Pay Now payment methods</h5>
      </vs-col>
      <hr class="line-hr mb-8 mt-2 w-full ml-4" />
      <vs-col vs-w="12" class="onboarding-form">
        <vs-row class="paymentOpt mb-8">
          <vs-col v-for="(paynowPlan, key) in product.payNowPaymentMethods" :key="key" class="w-auto">
            <div class="radio-card" v-if="paynowPlan.isChecked">
              <vs-row>
                <vs-col class="flex radio-label justify-between">
                  <p class="radio-label lg:text-xl text-lg">{{ paynowPlan.title }}</p>
                  <S3ImageGenerator :document="paynowPlan.icon" :custom-class="'card-image-sm'" :is-base64="false"/>
                </vs-col>
              </vs-row>

              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div>
                    <span class="flex items-center">
                      <p class="text-xs">Merchant service fee:</p>
                      <h6 class="ml-2 text-xs">{{ addZeroes(parseFloat(paynowPlan.msf)) }} %</h6>
                    </span>
                    <span class="flex items-center">
                      <p class="text-xs">Surcharge to customer:</p>
                      <h6 class="ml-2 text-xs">{{ addZeroes(parseFloat(paynowPlan.surcharge)) }} %</h6>
                    </span>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

    <vs-row v-if="product.productOption.includes('pay-later')">
      <vs-col vs-w="12" class="flex items-center ml-4">
        <div class="flex justify-center items-center" style="max-width: 20px">
          <img class="icon-control" :src="productLight" alt="product">
        </div>
        <h5 class="ml-5 mb-0">Pay Later products</h5>
      </vs-col>
      <hr class="line-hr mb-8 mt-2 w-full ml-4">
      <vs-col vs-w="12" class="onboarding-form">
        <vs-row class="paymentOpt">
          <vs-col v-for="(payLaterPlan, key) in product.payLaterProducts" :key="key" class="w-auto">
            <div class="radio-card" v-if="payLaterPlan.isChecked">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">{{ payLaterPlan.productName }}</vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div>
                    <span class="flex items-center">
                      <p class="text-xs">Service fee:</p>
                      <h6 class="ml-2 text-xs">{{ addZeroes(parseFloat(payLaterPlan.msf)) }} %</h6>
                    </span>
                    <span class="flex items-center" v-if="payLaterPlan.feeModel == 'MSF_INCLUDED'">
                      <p class="text-xs">(payable by Merchant)</p>
                    </span>
                    <span class="flex items-center" v-else>
                      <p class="text-xs">(payable by Customer)</p>
                    </span>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import S3ImageGenerator from "@/views/components/S3ImageGenerator";
const productLight = require("@/assets/images/product-grey.svg");

export default {
  name: "ProductSelection",
  components: {
    S3ImageGenerator,
  },
  props: {
    product: { type: Object },
  },
  data(){
    return {
      productLight: productLight
    }
  },
  methods: {
    addZeroes(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>