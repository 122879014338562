<template>
  <div>
    <div class="onboarding-form">
      <!-------onboarding custom fields------>
      <div class="flex mt-6 flex-wrap" v-if="onboardingCustomFields && onboardingCustomFields.length > 0">
        <div class="input-grp mt-8" v-for="(customField, cfindex) in onboardingCustomFields" :key="cfindex">
          <label class="vs-input--label pl-0 review-label w-full">{{customField.label}}</label>
          <p class="textBlack" v-if="customField.dataType === 'currency'">$ {{ formatToCurrency(customField.value) }}</p>
          <p class="textBlack" v-else-if="customField.dataType === 'number'">{{ formatToCurrency(customField.value) }}</p>
          <p class="textBlack" v-else>{{ customField.value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessProfile",
  props:["onboardingCustomFields"],
   methods:{
    formatToCurrency(amount){
      if(!amount) return amount;
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter.format(amount).replace("$",""); 
    }

  }
}
</script>